import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  apiKycStatus,
  documentType,
  insurers,
  kycDoc,
  kycDocName,
  kycStepPriority,
  kycType,
  moveToTop,
  toastFailure,
  toastSuccess,
} from "../utility/constants";
import { useForm } from "react-hook-form";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
} from "../../store/action/userActions";
import {
  addDocuments,
  getKyc,
  getKycServiceStatus,
  processFlow,
} from "../services/TPApiCall";
import { getVehiclePincode } from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelectProposal";
import { toast } from "react-toastify";
import RedirectionModal from "./RedirectionModal";
import { useNavigate } from "react-router-dom";
import { select } from "react-cookies";
import moment from "moment";
import DatePicker from "react-datepicker";
import ReactInputMask from "react-input-mask";
import jsPDF from "jspdf";
import { KYC_CONFIG } from "../../store/types";
import { PDFDocument } from "pdf-lib";
import VerifyKycModal from "./VerifyKycModal";
import OtpModel from "../common/OtpModel";

const KYC = ({
  setActiveStep2,
  setCurrentStep,
  setActiveStep3,
  kycStatusCheck,
  kycMode,
  setKycMode,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm();

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue: setValue1,
    clearErrors: clearErrors1,
  } = useForm();

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const kycConfig = useSelector((state) => state?.root?.kycConfig);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const [proceedSpinner, setProceedSpinner] = useState(false);

  const [kycStatus, setKycStatus] = useState(false);
  const [pincodeData, setPincodeData] = useState([]);
  const [pincodeError, setPincodeError] = useState(false);
  const [kycFields, setKycFields] = useState("");
  const [gstin, setGstin] = useState(
    motorRequest?.gstin ? motorRequest?.gstin : null
  );
  const [gstInError, setGstInError] = useState(false);
  const [address, setAddress] = useState({
    addressLine1: motorRequest?.addressLine1 || "",
    addressLine2: motorRequest?.addressLine2 || "",
    addressLine3: motorRequest?.addressLine3 || "",
    pincode: motorRequest?.pincode || "",
  });
  const [addressErrors, setAddressErrors] = useState({});
  const [maritalStatus, setMaritalStatus] = useState(
    motorRequest?.maritalStatus ? motorRequest?.maritalStatus : null
  );
  const [redirectionModal, setRedirectionModal] = useState(false);
  const [verifyKycModal, setVerifyKycModal] = useState(
    motorRequest?.isRedirection ? motorRequest?.isRedirection : false
  );
  const [redirection, setRedirection] = useState(
    motorRequest?.isRedirection ? motorRequest?.isRedirection : false
  );
  const [images, setImages] = useState(null);
  const [imagesLink, setImagesLink] = useState(null);
  const [insuredImageLink, setInsuredImageLink] = useState(null);
  const [imageLinkError, setImageLinkError] = useState(false);
  const [docImages, setDocImages] = useState({
    frontId: null,
    backId: null,
    insuredPhotoId: null,
    form60Id: null,
  });
  const [allowedFormat, setAllowedFormat] = useState(false);
  const [email, setEmail] = useState(motorRequest?.email || "");
  const [redirectionUrl, setRedirectionUrl] = useState(null);
  const [dob, setDob] = useState(
    motorRequest?.dob ? new Date(motorRequest.dob) : null
  );
  const [dobError, setDobError] = useState(false);
  const [address1, setAddress1] = useState(
    motorRequest?.kycData?.addressLine1
      ? motorRequest?.kycData?.addressLine1
      : ""
  );
  const [address2, setAddress2] = useState(
    motorRequest?.kycData?.addressLine2
      ? motorRequest?.kycData?.addressLine2
      : ""
  );
  const [address3, setAddress3] = useState(
    motorRequest?.kycData?.addressLine3
      ? motorRequest?.kycData?.addressLine3
      : ""
  );
  const [hasEdited, setHasEdited] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(
    motorRequest?.docName ? motorRequest?.docName : ""
  );
  const [aadharSingleImage, setAadharSingleImage] = useState(false);
  const [displayOtpField, setDisplayOtpField] = useState(false);
  const [otpLength, setOtpLength] = useState(null);

  useEffect(() => {
    if (
      selectedPlan?.insurerId !== insurers.Future ||
      selectedPlan?.insurerId !== insurers.Magma
    ) {
      dispatchMotorQuote("pucNumber", "");
      dispatchMotorQuote("pucValidUpto", "");
    }
  }, []);

  useEffect(() => {
    if (motorRequest?.frontId === "" || motorRequest?.backId === "") {
      console.log("selectedDocument", selectedDocument);
      if (selectedDocument) {
        handleImages(selectedDocument);
      }
    }
  }, [motorRequest?.frontId, motorRequest?.backId]);

  useEffect(() => {
    if (
      motorRequest?.kycData?.addressLine1 &&
      motorRequest?.kycData?.addressLine1 !== address1
    ) {
      setAddress1(motorRequest?.kycData?.addressLine1);
      dispatchMotorQuote("addressLine1", motorRequest?.kycData?.addressLine1);
    }
    if (
      motorRequest?.kycData?.addressLine2 &&
      motorRequest?.kycData?.addressLine2 !== address2
    ) {
      setAddress2(motorRequest?.kycData?.addressLine2);
      dispatchMotorQuote("addressLine2", motorRequest?.kycData?.addressLine2);
    }
    if (
      motorRequest?.kycData?.addressLine3 &&
      motorRequest?.kycData?.addressLine3 !== address3
    ) {
      setAddress3(motorRequest?.kycData?.addressLine3);
      dispatchMotorQuote("addressLine3", motorRequest?.kycData?.addressLine3);
    }
  }, [
    motorRequest?.kycData?.addressLine1,
    motorRequest?.kycData?.addressLine2,
    motorRequest?.kycData?.addressLine3,
    address1, // include these to prevent unnecessary updates
    address2,
    address3,
  ]);

  useEffect(() => {
    const selectedPincode = pincodeData.find(
      (pincode) => pincode.value === motorRequest?.pincode
    );

    // Only reset if a valid pincode is found and it's not the same as the previous one
    if (selectedPincode) {
      if (
        selectedPincode?.City !== getValues("city") ||
        selectedPincode?.State !== getValues("state")
      ) {
        setValue("city", selectedPincode?.City);
        setValue("state", selectedPincode?.State);
        // reset({
        //   city: selectedPincode?.City,
        //   state: selectedPincode?.State,
        // });
      }

      // Dispatch motor quote only if the city/state/pincode has changed
      dispatchMotorQuote("cityId", selectedPincode?.cityId);
      dispatchMotorQuote("stateId", selectedPincode?.stateId);
      dispatchMotorQuote("city", selectedPincode?.City);
      dispatchMotorQuote("state", selectedPincode?.State);
    }
  }, [motorRequest?.pincode, pincodeData, getValues, reset]); // Include necessary dependencies

  const handleAddressChange1 = (e) => {
    setAddress1(e.target.value);
    dispatchMotorQuote("motorRequest.kycData.addressLine1", e?.target?.value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (kycStatusCheck && kycConfig) {
      const kycField = kycConfig?.KycFieldConfig.filter(
        (kycType) => kycType.kycType === motorRequest?.kycType?.toLowerCase()
      );
      setKycFields(kycField);

      dispatchMotorQuote("kycFields", kycField);

      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest, kycFields: kycField },
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      processFlow(dataForFlow);
    }
    setValue("docName", motorRequest?.docName);
  }, [kycConfig, kycStatusCheck, motorRequest?.kycType]);

  // const handleOVDMode = () => {
  //   setKycMode("ovd");
  //   const ovdFields = kycConfig?.KycFieldConfig.filter(
  //     (kycType) => kycType.kycType === "ovd"
  //   );
  //   console.log("ovdFields", ovdFields);
  //   setKycFields(ovdFields);
  //   dispatchMotorQuote("kycType", "ovd");
  // };

  const getKycStatus = async () => {
    setProceedSpinner(true);
    const postData = {
      caseId: motorRequest?.caseId,
      vehicleType: motorRequest?.vehicleType,
      insurerId: selectedPlan?.insurerId,
    };
    const response = await getKycServiceStatus(postData);
    console.log("response", response);
    if (
      response?.status === "success" &&
      response?.data?.kycStatus == "success"
    ) {
      const newDataForFlow = {
        ...processFlowObjMotor?.step8?.kycAndUserDetails,
      };
      newDataForFlow.firstName = motorRequest?.firstName;
      console.log(" data.firstName", newDataForFlow);
      const newData = {
        ...newDataForFlow,
        kycStatus: "completed",
        kycType: motorRequest?.kycType,
      };
      console.log("newData", newData);
      dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);
      dispatchMotorQuote("kycStatus", "completed");
      dispatchMotorQuote("kycData", response?.data?.kycData);
      setKycStatus(true);
      toastSuccess("KYC Successful");
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      // debugger;
      processFlow(dataForFlow);
      setVerifyKycModal(false);
      setProceedSpinner(false);
    } else {
      setProceedSpinner(false);
      setVerifyKycModal(false);
      toastFailure(
        `${
          response?.data?.errors
            ? response?.data?.errors[0]?.displayMessage
            : "KYC Failed. Please check your details and try again"
        }`
      );
    }
  };

  const IsEdited = (e) => {
    setHasEdited(true);
    handleAddressChange(e);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    if (name === "addressLine1") {
      if (value.trim() === "" && hasEdited) {
        // Set error if there is no default value and input is empty
        setAddressErrors((prev) => ({
          ...prev,
          addressLine1: "Address 1 is required",
        }));
      } else {
        // Clear error if input has a value or there is a default value
        setAddressErrors((prev) => ({
          ...prev,
          addressLine1: "",
        }));
      }
    } else if (name === "addressLine2") {
      if (value.trim() === "" && hasEdited) {
        setAddressErrors((prev) => ({
          ...prev,
          addressLine2: "Address 2 is required",
        }));
      } else {
        setAddressErrors((prev) => ({
          ...prev,
          addressLine2: "",
        }));
      }
    }
    //  else if (name === "addressLine3") {
    //   setAddressErrors((prev) => ({
    //     ...prev,
    //     addressLine3: "",
    //   }));
    // }
    console.log("handleAddressChange", value);
    console.log("handleAddressChange", name);
    setAddress({ ...address, [name]: value });
    dispatchMotorQuote(name, value);
  };

  const handleGstin = (e) => {
    // setGstInError(false);
    setGstin(e.target.value);
    dispatchMotorQuote("gstIn", e.target.value.toUpperCase());
  };

  const validateAddressFields = () => {
    let isValid = true;
    const newErrors = {};

    if (!address.addressLine1) {
      newErrors.addressLine1 = "Address 1 is required";
      isValid = false;
    }
    if (!address.addressLine2) {
      newErrors.addressLine2 = "Address 2 is required";
      isValid = false;
    }
    console.log("maritalStatus", maritalStatus);
    if (
      !(
        (kycConfig &&
          kycFields &&
          kycFields.some((field) =>
            field.requiredField.includes("fatherName")
          )) ||
        (kycConfig &&
          kycFields &&
          kycFields.some((field) => field.requiredField.includes("spouseName")))
      )
    ) {
      if (motorRequest?.customerType !== CUSTOMER_TYPE.ORGANISATION) {
        if (!maritalStatus) {
          newErrors.maritalStatus = "Marital Status is required";
          isValid = false;
        }
      }
    }
    // if (!address.addressLine3) {
    //   newErrors.addressLine3 = "Address 3 is required";
    //   isValid = false;
    // }

    setAddressErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    console.log("kycConfig", kycConfig);
    console.log("kycConfig", kycFields);
    console.log("kycConfig", kycStatusCheck);
    console.log("kycConfig", getValues());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleNextClick = () => {
    console.log("motorREQUEST", motorRequest);
    console.log("motorREQUEST errors", errors);
    if (motorRequest?.pincode === "") {
      setPincodeError(true);
      return;
    }

    // if (selectedPlan?.insurerId === 17) {
    //   if (
    //     motorRequest?.customerType === CUSTOMER_TYPE.ORGANISATION &&
    //     selectedPlan?.isGstNumberRequiredInProposal &&
    //     !gstin
    //   ) {
    //     setGstInError(true);
    //     return;
    //   } else {
    //     setGstInError(false);
    //   }
    // }
    if (validateAddressFields()) {
      selectedPlan?.PersonalAccident
        ? (setCurrentStep(2), setActiveStep2(true))
        : (setCurrentStep(3), setActiveStep3(true));
      // setActiveStep2(true);
      // setCurrentStep(2);
      console.log("processFlowObjMotor", processFlowObjMotor);
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          kycAndUserDetails: {
            ...processFlowObjMotor.step8.kycAndUserDetails,
            maritalStatus: motorRequest?.maritalStatus,
            addressLine1: motorRequest?.addressLine1,
            addressLine2: motorRequest?.addressLine2,
            addressLine3: motorRequest?.addressLine3,
            cityId: motorRequest?.cityId,
            city: motorRequest?.city,
            stateId: motorRequest?.stateId,
            state: motorRequest?.state,
            pincode: motorRequest?.pincode,
          },
          motorRequest: { ...motorRequest },
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      // debugger;
      processFlow(dataForFlow);
    }
  };

  useEffect(() => {
    getVehiclePincode()
      .then((response) => {
        console.log("RESPONSE PINCODE DATA", response);
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
              cityId: response.data[i].cityId,
              stateId: response.data[i].stateId,
            });

            i++;
          }
          // while (j < response.data.length) {
          //   arr1.push({
          //     value: response.data[j].Pin_Code,
          //     label: response.data[j].District,
          //     District: response.data[j].District,
          //     City: response.data[j].City,
          //     State: response.data[j].State,
          //   });

          //   j++;
          // }
          setPincodeData(arr);
          console.log("ARRAY PINCODE DATA", arr);
          // setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function capitalizeValue(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  const submitKycDetails = async (data) => {
    console.log("dataaaaa", data);
    dispatchMotorQuote("docImages", docImages);

    // Ensure email is updated in Redux before proceeding
    if (email) {
      dispatchMotorQuote("email", email);
    }

    console.log(
      "kycConfig?.kycConfig?.kycStep",
      kycConfig?.kycConfig?.kycStep === kycStepPriority.in_proposal
    );
    console.log("kycConfig?.kycConfig?.kycStep", kycConfig?.kycConfig?.kycStep);
    console.log("kycConfig?.kycConfig?.kycStep", kycStepPriority.in_proposal);

    if (
      kycFields &&
      kycFields
        .find((field) => field.docName === selectedDocument)
        .requiredField.includes("pincode") &&
      motorRequest?.pincode === ""
    ) {
      setPincodeError(true);
      return;
    }

    if (kycConfig?.kycConfig?.kycStep === "in_proposal") {
      if (!validateAddressFields()) {
        return;
      }
      if (motorRequest?.pincode === "") {
        setPincodeError(true);
        return;
      }
    }

    if (!dob) {
      setDobError(true);
      return;
    }
    validateDocFields();
    handleImagesUploadToS3(data);
    setProceedSpinner(true);
    console.log("KYC DATA", data);
    let newData = { ...motorRequest };
    if (
      selectedPlan?.Api_name === insurers.Digit.name ||
      selectedPlan?.insurerId === insurers.Digit.insurerId ||
      selectedPlan?.insurerId === insurers.shriram.insurerId ||
      selectedPlan?.insurerId === insurers.TATA.insurerId
    ) {
      let nameForDigit = {
        firstName: "",
        middleName: "",
        lastName: "",
      };

      for (let key in data) {
        if (key === "documentNo") {
          dispatchMotorQuote(`docNumber`, data[key]);
          newData[key] = data[key];
        } else {
          dispatchMotorQuote(`${key}`, data[key]);
          newData[key] = data[key];
        }
      }

      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: {
            ...motorRequest,
            docName: newData?.docName,
            docNumber: newData?.docNumber,
            gender: newData?.gender,
          },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      processFlow(dataForFlow);
      if (selectedPlan?.PersonalAccident) {
        setCurrentStep(2);
        return;
      } else {
        setCurrentStep(3);
        return;
      }
    }

    let nameForKyc = {
      firstName: "",
      middleName: "",
      lastName: "",
    };

    if (!kycStatus) {
      for (let key in data) {
        dispatchProcessFlowMotor("step", "step8");

        dispatchMotorQuote(`${key}`, data[key]);
        newData[key] = data[key];
      }
      dispatchProcessFlowMotor(`step8.kycAndUserDetails`, data);

      try {
        console.log("newData getKyc", newData);
        const response = await getKyc(newData, selectedPlan, kycFields);
        console.log("kyc response", response);

        if (
          response?.data?.isOtpRequired &&
          response?.data?.kycStatus === apiKycStatus.pending
        ) {
          setDisplayOtpField(true);
          setOtpLength(response?.data?.maxOtpLengthAllowed);
          let newDataForFlow = { ...data };
          console.log("newDataForFlow", newDataForFlow);
          // newDataForFlow.firstName = motorRequest?.firstName;
          console.log(" data.firstName", newDataForFlow);
          const newData = {
            ...newDataForFlow,
            kycStatus: apiKycStatus.pending,
            kycType: motorRequest?.kycType,
          };
          console.log("newData", newData);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: {
              ...motorRequest,
              docName: newData?.docName,
              docNumber: newData?.docNumber,
              gender: newData?.gender,
            },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          console.log("dataForFlow", dataForFlow);
          // debugger;
          processFlow(dataForFlow);
          return;
        }

        if (response?.data?.kycStatus === apiKycStatus.success) {
          dispatchProcessFlowMotor("step", "step8");
          let newDataForFlow = { ...data };
          newDataForFlow.firstName = motorRequest?.firstName;
          console.log(" data.firstName", newDataForFlow);
          const newData = {
            ...newDataForFlow,
            kycStatus: "completed",
            kycType: motorRequest?.kycType,
          };
          console.log("newData", newData);
          dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);

          dispatchMotorQuote("kycStatus", "completed");
          dispatchMotorQuote("kycData", response?.data?.kycData);
          toast.success("KYC Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setKycStatus(true);
          console.log("new Data for firstNAme", newData);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: {
                ...motorRequest,
                docName: newData?.docName,
                docNumber: newData?.docNumber,
                gender: newData?.gender,
              },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          console.log("dataForFlow", dataForFlow);
          // debugger;
          processFlow(dataForFlow);
          setProceedSpinner(false);
        } else if (response?.data?.kycStatus === "failure") {
          console.log(response, "response");
          dispatchProcessFlowMotor("step", "step8");
          if (kycConfig?.kycConfig?.isOvdAllowed) {
            dispatchMotorQuote("ovdActive", true);
          }
          let newDataForFlow = { ...data };
          console.log("newDataForFlow", newDataForFlow);
          // newDataForFlow.firstName = motorRequest?.firstName;
          console.log(" data.firstName", newDataForFlow);
          const newData = {
            ...newDataForFlow,
            kycStatus: "failure",
            kycType: motorRequest?.kycType,
          };
          console.log("newData", newData);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: {
              ...motorRequest,
              docName: newData?.docName,
              docNumber: newData?.docNumber,
              gender: newData?.gender,
            },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          console.log("dataForFlow", dataForFlow);
          // debugger;
          processFlow(dataForFlow);
          // const data = { ...processFlowObjMotor, kycStatus: "failure" };
          // dispatchProcessFlowMotor("step8.kycAndUserDetails", data);
          dispatchMotorQuote("kycStatus", "failure");

          const newMotorRequestData = {
            ...motorRequest,
            isRedirection: true,
            kycStatus: "failure",
          };
          toastFailure(
            `Your KYC has been failed. We are redirecting you to Insurer's Portal`
          );
          if (response?.data?.redirectionUrl) {
            setRedirectionUrl(response?.data?.redirectionUrl);
            setTimeout(() => {
              setRedirection(true);
              window.open(response?.data?.redirectionUrl, "_blank");
              const dataForFlow = {
                ...processFlowObjMotor,
                customerLeadId: motorRequest?.customerLeadId,
                // motorRequest: {
                //   ...motorRequest,
                //   docName: newData?.docName,
                //   docNumber: newData?.docNumber,
                //   gender: newData?.gender,
                // },
                processDiscription: "kycAndUserDetails",
                step: "step8",
                step8: {
                  ...processFlowObjMotor.step8,
                  motorRequest: { ...newMotorRequestData },
                  kycAndUserDetails: newData,
                  kycConfig: kycConfig,
                  planDetails: selectedPlan,
                },
              };
              setVerifyKycModal(true);
            }, [2000]);
            setProceedSpinner(false);
            return;
          }

          toastFailure(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`
          );
          setProceedSpinner(false);
        } else {
          setProceedSpinner(false);
          toast.error(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } catch (error) {
        toast.error(
          `${"KYC Failed. Please check your details and try again"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        console.error("Error occurred:", error);
        setProceedSpinner(false);
      }
    }

    if (kycStatus) {
      setActiveStep2(true);
    }
  };

  const validateDocFields = () => {
    if (motorRequest?.kycType === "ovd") {
      console.log("watchName", watch("docName"));

      console.log("kycFIELDS", kycFields);

      const reqField = kycFields.find(
        (doc) => doc?.docName === watch("docName")
      ).requiredField;
      console.log("reqField", reqField);

      if (reqField.includes("uploadFrontPage")) {
        if (!docImages?.frontId && !motorRequest?.frontId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("uploadBackPage")) {
        if (!docImages?.backId && !motorRequest?.backId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("insured_photo")) {
        if (!docImages?.insuredPhotoId && !motorRequest?.insuredPhotoId) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
      if (reqField.includes("form60Link")) {
        if (!docImages?.form60Id && !motorRequest?.form60Id) {
          setImageLinkError(true);
          moveToTop();
          return;
        }
      }
    }
  };

  const handleSelectPincode = (data) => {
    setPincodeError(false);
    console.log("pincode data", data);

    if (motorRequest?.pincode !== data?.value) {
      dispatchMotorQuote("pincode", data?.value);
    }

    if (motorRequest?.city !== data?.City) {
      dispatchMotorQuote("city", data?.City);
    }

    if (motorRequest?.state !== data?.State) {
      dispatchMotorQuote("state", data?.State);
    }

    if (motorRequest?.cityId !== data?.cityId) {
      dispatchMotorQuote("cityId", data?.cityId);
    }

    if (motorRequest?.stateId !== data?.stateId) {
      dispatchMotorQuote("stateId", data?.stateId);
    }

    setValue("city", data?.City);
    setValue("state", data?.State);

    // reset({
    //   // docName: getValues("docName"),
    //   city: data?.City,
    //   state: data?.State,
    // });
  };

  const handleUploadFile = (e) => {
    console.log("e.target.files", e.target);
  };

  const handleInputChange = (e) => {
    console.log("handleInputChange", e.target.name, e.target.value);
    const value =
      e.target.name === "firstName"
        ? e.target.value
        : e.target.name === "lastName"
        ? e.target.value
        : e.target.value.toUpperCase();
    setValue(e.target.name, value);
  };

  const handleMaritalStatus = (e) => {
    const value = e.target.value;
    console.log("value", value);
    setMaritalStatus(value);
    setAddressErrors((prev) => ({
      ...prev,
      maritalStatus: "",
    }));
    dispatchMotorQuote("maritalStatus", value);
  };

  const handlePreviousPage = () => {
    const newData = {
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      motorRequest: { ...motorRequest },
      processDiscription: "quotation",
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...motorRequest },
        QuickQouteResult: { ...QuickQouteResult },
      },
    };
    processFlow(newData);
    setTimeout(() => {
      navigate(`/quotelist/${motorRequest?.customerLeadId}`);
    }, [1000]);
  };

  // Handle date change from DatePicker
  const handleDobChange = (date) => {
    if (date) {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      setDob(date);
      setValue("dob", formattedDate);
      dispatchMotorQuote("dob", formattedDate);
      setDobError(false);
    } else {
      setDob(null);
      setValue("dob", "");
      dispatchMotorQuote("dob", "");
    }
  };

  // Handle manual input change with mask
  const handleDobInputChange = (event) => {
    const inputValue = event.target.value;

    if (/^\d{2}\/\d{2}\/\d{4}$/.test(inputValue)) {
      const [day, month, year] = inputValue.split("/");
      const date = new Date(`${year}-${month}-${day}`);

      if (!isNaN(date.getTime())) {
        setDob(date);
        const formattedDate = moment(date).format("YYYY-MM-DD");
        setValue("dob", formattedDate);
        dispatchMotorQuote("dob", formattedDate);
      } else {
        setDob(null);
        setValue("dob", "");
        dispatchMotorQuote("dob", "");
      }
    } else {
      setDob(null);
      setValue("dob", "");
      dispatchMotorQuote("dob", "");
    }
  };

  const handleFileUpload = (e) => {
    console.log("handleFileUpload", e.target.files);
    if (e.target.files) {
      if (
        e.target.files[0].type.includes("image") ||
        e.target.files[0].type.includes("pdf")
      ) {
        setImages(e.target.files[0]);
        setImageLinkError(false);
      } else {
        alert("Error in uploading image");
      }
    } else {
      console.log("No file selected");
    }
  };

  const handleKycDocUpload = async (pdfBlob, docName) => {
    // const hasBothImages = docImages.frontId && docImages.backId;
    // if (hasBothImages) {
    console.log("pdfBlob handleKycDocUpload", pdfBlob);
    const postData = {
      caseId: selectedPlan?.caseId,
      documentType: documentType.kyc,
      file: pdfBlob,
    };
    console.log("postData handleKycDocUpload", postData);
    try {
      const response = await addDocuments(postData);
      console.log("response", response);
      if (response?.status === "success") {
        // debugger;
        if (docName === "insuredPhotoId") {
          setInsuredImageLink(response?.data);

          console.log("insured_photo", response?.data, docName);
          dispatchMotorQuote("insured_photo", response?.data);
        } else if (docName === "form60Id") {
          setInsuredImageLink(response?.data);
          console.log("form60Link", response?.data, docName);
          dispatchMotorQuote("form60Link", response?.data);
        } else {
          if (watch("docName") === kycDoc.pan) {
            dispatchMotorQuote("panFileLink", response?.data);
          }
          if (watch("docName") === kycDoc.adhaar) {
            dispatchMotorQuote("aadhaarFileLink", response?.data);
          }
          if (watch("docName") === kycDoc.passport) {
            dispatchMotorQuote("passportFileLink", response?.data);
          }
          setImagesLink(response?.data);
          setValue("docLink", response?.data);
          console.log("kycImageLink", response?.data, docName);
          dispatchMotorQuote("kycImageLink", response?.data);
        }
      } else {
        toastFailure(
          response?.data?.errors[0]?.displayMessage ||
            "Error in uploading image"
        );
      }
    } catch (error) {
      console.log("Error in uploading doc", error);
    }
    // }
  };

  useEffect(() => {
    console.log("images", images);
    if (images) {
      // handleKycDocUpload();
    }
  }, [images]);

  useEffect(() => {
    if (getValues("city") === undefined && getValues("state") === undefined) {
      if (
        motorRequest?.cityId &&
        motorRequest?.city &&
        motorRequest?.stateId &&
        motorRequest?.state
      ) {
        setValue("city", motorRequest?.city);
        setValue("state", motorRequest?.state);
      }
    }
  }, [errors]);

  const handlePictureUpload = async (e) => {
    const file = e.target.files[0];
    const fieldName = e.target.name;
    // const url = URL.createObjectURL(file);
    // console.log("urllll", url);
    if (
      file &&
      (file.type.includes("image/jpg") ||
        file.type.includes("image/jpeg") ||
        file.type.includes("application/pdf"))
    ) {
      setAllowedFormat(false);
      if (watch("docName") === kycDoc.pan && fieldName === "frontId") {
        dispatchMotorQuote("panFileName", file.name);
      }
      if (watch("docName") === kycDoc.adhaar && fieldName === "frontId") {
        dispatchMotorQuote("aadhaarFrontFileName", file.name);
      }
      if (watch("docName") === kycDoc.adhaar && fieldName === "backId") {
        dispatchMotorQuote("aadhaarBackFileName", file.name);
      }
      if (watch("docName") === kycDoc.passport && fieldName === "frontId") {
        dispatchMotorQuote("passportFileName", file.name);
      }

      dispatchMotorQuote(fieldName, file.name);
      console.log("fileeeee", fieldName, file, file.type);
      if (
        file &&
        (file.type.includes("image/") || file.type.includes("application/pdf"))
      ) {
        setDocImages((prev) => ({
          ...prev,
          [fieldName]: file,
        }));
      }
    } else {
      setAllowedFormat(true);
      toastFailure("Please Upload Image in jpg or jpeg format or in pdf");
      e.target.value = "";

      // setDocImages((prev) => ({
      //   ...prev,
      //   [fieldName]: null, // or delete the fieldName property if you prefer
      // }));
      return;
    }

    // if (fieldName === "insuredPhotoId") {
    //   await handleKycDocUpload(file, "insuredPhotoId");
    // } else if (fieldName === "form60Id") {
    //   await handleKycDocUpload(file, "form60Id");
    // }
  };

  const generatePDF = async (docName, isFrontOnly) => {
    // Create a new PDF document that will hold everything
    const mergedPdf = await PDFDocument.create();

    // Handle front document
    if (docImages.frontId) {
      if (docImages.frontId.type === "application/pdf") {
        // Handle PDF front
        const frontPdfBytes = await docImages.frontId.arrayBuffer();
        const frontPdf = await PDFDocument.load(frontPdfBytes);
        const frontPages = await mergedPdf.copyPages(
          frontPdf,
          frontPdf.getPageIndices()
        );
        frontPages.forEach((page) => mergedPdf.addPage(page));
      } else {
        // Handle image front
        const frontImageData = await getBase64(docImages.frontId);
        const tempPdf = new jsPDF();
        tempPdf.addImage(frontImageData, "JPEG", 10, 10, 180, 160);
        const tempPdfBytes = tempPdf.output("arraybuffer");
        const frontImagePdf = await PDFDocument.load(tempPdfBytes);
        const [frontPage] = await mergedPdf.copyPages(frontImagePdf, [0]);
        mergedPdf.addPage(frontPage);
      }

      // If frontOnly is true, save and return
      if (isFrontOnly) {
        const mergedPdfBytes = await mergedPdf.save();
        const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        // previewPDF(pdfBlob);
        await handleKycDocUpload(pdfBlob, docName);
        return;
      }
    }

    // Handle back document if not frontOnly
    if (!isFrontOnly && docImages.backId) {
      if (docImages.backId.type === "application/pdf") {
        // Handle PDF back
        const backPdfBytes = await docImages.backId.arrayBuffer();
        const backPdf = await PDFDocument.load(backPdfBytes);
        const backPages = await mergedPdf.copyPages(
          backPdf,
          backPdf.getPageIndices()
        );
        backPages.forEach((page) => mergedPdf.addPage(page));
      } else {
        // Handle image back
        const backImageData = await getBase64(docImages.backId);
        const tempPdf = new jsPDF();
        tempPdf.addImage(backImageData, "JPEG", 10, 10, 180, 160);
        const tempPdfBytes = tempPdf.output("arraybuffer");
        const backImagePdf = await PDFDocument.load(tempPdfBytes);
        const [backPage] = await mergedPdf.copyPages(backImagePdf, [0]);
        mergedPdf.addPage(backPage);
      }
    }

    // Save and upload the final merged PDF
    const mergedPdfBytes = await mergedPdf.save();
    const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
    // previewPDF(pdfBlob);
    await handleKycDocUpload(pdfBlob, docName);
  };

  // Function to preview PDF
  const previewPDF = (pdfBlob) => {
    // Create a URL for the PDF blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Create and open preview in a new window
    const previewWindow = window.open(pdfUrl, "_blank");

    // Clean up the URL object after the window is closed
    if (previewWindow) {
      previewWindow.onclose = () => {
        URL.revokeObjectURL(pdfUrl);
      };
    }

    // Fallback if popup is blocked
    if (!previewWindow) {
      const viewer = document.createElement("iframe");
      viewer.style.width = "100%";
      viewer.style.height = "500px";
      viewer.src = pdfUrl;

      // Create a modal or container for the viewer
      const modal = document.createElement("div");
      modal.style.position = "fixed";
      modal.style.top = "0";
      modal.style.left = "0";
      modal.style.width = "100%";
      modal.style.height = "100%";
      modal.style.backgroundColor = "rgba(0,0,0,0.8)";
      modal.style.zIndex = "1000";
      modal.style.display = "flex";
      modal.style.flexDirection = "column";
      modal.style.alignItems = "center";
      modal.style.padding = "20px";

      // Add close button
      const closeButton = document.createElement("button");
      closeButton.textContent = "Close Preview";
      closeButton.style.marginBottom = "10px";
      closeButton.onclick = () => {
        document.body.removeChild(modal);
        URL.revokeObjectURL(pdfUrl);
      };

      modal.appendChild(closeButton);
      modal.appendChild(viewer);
      document.body.appendChild(modal);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  useEffect(() => {
    console.log("docName--------", watch("docName"));
    console.log("docImage--------", docImages);
    // if (kycConfig && kycFields) {
    //   kycFields.forEach((field) => {

    if (watch("docName") === "pan") {
      let docName = "frontOnly";
      // generatePDF(docName, true);
    }
    // });

    const hasBothImages = docImages.frontId && docImages.backId;
    if (hasBothImages) {
      let docName = "bothSide";
      // generatePDF(docName, false);
    }
    console.log("docImages", docImages);
    // }
  }, [docImages.frontId, docImages.backId]);

  console.log("errorssss", errors);

  const handleRemoveFile = (key) => {
    dispatchMotorQuote(key, "");
    docImages[key] = null;
    if (key === "form60Id") {
      dispatchMotorQuote("form60Id", "");
      dispatchMotorQuote("form60Link", "");
    }
    console.log("keyyyyyyy", key, selectedDocument);
    if (selectedDocument === kycDoc.adhaar && key === "frontId") {
      dispatchMotorQuote("aadhaarFrontFileName", "");
    } else if (selectedDocument === kycDoc.adhaar && key === "backId") {
      dispatchMotorQuote("aadhaarBackFileName", "");
    } else if (selectedDocument === kycDoc.pan && key === "frontId") {
      dispatchMotorQuote("panFileName", "");
      dispatchMotorQuote("panFileLink", "");
    } else if (selectedDocument === kycDoc.passport && key === "frontId") {
      dispatchMotorQuote("passportFileName", "");
      dispatchMotorQuote("passportFileLink", "");
    }
  };

  const handleDocNameChange = (e) => {
    const selectedDocName = e.target.value;
    console.log("Selected Doc Name: ", e.target.value);
    setValue("docName", selectedDocName); // Manually trigger form state update
    dispatchMotorQuote("docName", selectedDocName);
    if (motorRequest?.docName === selectedDocName) {
      setValue("docNumber", motorRequest?.docNumber);
      dispatchMotorQuote("docNumber", motorRequest?.docNumber);
    } else if (motorRequest?.docName !== selectedDocName) {
      setValue("docNumber", "");
      dispatchMotorQuote("docNumber", "");

      if (selectedDocName === kycDoc.pan) {
        if (motorRequest?.panFileName && motorRequest?.panFileLink) {
          dispatchMotorQuote("frontId", motorRequest?.panFileName);
        } else {
          dispatchMotorQuote("frontId", "");
        }
      } else if (selectedDocName === kycDoc.adhaar) {
        if (
          motorRequest?.aadhaarFrontFileName &&
          motorRequest?.aadhaarBackFileName &&
          motorRequest?.aadhaarFileLink
        ) {
          dispatchMotorQuote("frontId", motorRequest?.aadhaarFrontFileName);
          dispatchMotorQuote("backId", motorRequest?.aadhaarBackFileName);
        } else {
          dispatchMotorQuote("frontId", "");
          dispatchMotorQuote("backId", "");
        }
      } else if (selectedDocName === kycDoc.passport) {
        if (motorRequest?.passportFileName && motorRequest?.passportFileLink) {
          dispatchMotorQuote("frontId", motorRequest?.passportFileName);
        } else {
          dispatchMotorQuote("frontId", "");
        }
      } else if (selectedDocName === kycDoc.dl) {
        if (
          motorRequest?.drivingLicenseFileName &&
          motorRequest?.drivingLicenseFileLink
        ) {
          dispatchMotorQuote("frontId", motorRequest?.drivingLicenseFileName);
        } else {
          dispatchMotorQuote("frontId", "");
        }
      } else if (selectedDocName === kycDoc.voterId) {
        if (motorRequest?.voterIdFileName && motorRequest?.voterIdFileLink) {
          dispatchMotorQuote("frontId", motorRequest?.voterIdFileName);
        } else {
          dispatchMotorQuote("frontId", "");
        }
      } else {
        dispatchMotorQuote("frontId", "");
      }

      // if (
      //   selectedDocument === kycDoc.pan &&
      //   selectedDocName === kycDoc.adhaar
      // ) {
      //   // handleRemoveFile("frontId", selectedDocName);
      //   dispatchMotorQuote("frontId", "");
      // } else if (
      //   selectedDocument === kycDoc.adhaar &&
      //   selectedDocName === kycDoc.pan
      // ) {
      //   dispatchMotorQuote("frontId", "");
      //   dispatchMotorQuote("backId", "");
      //   // handleRemoveFile("frontId", selectedDocName);
      //   // handleRemoveFile("backId", selectedDocName);
      // }
    }
    setSelectedDocument(selectedDocName);
    handleImages(selectedDocName);
  };

  const handleImages = (selectedDocName) => {
    console.log(
      "selectedDocName",
      selectedDocName,
      motorRequest?.frontId,
      motorRequest?.backId
    );
    if (
      selectedDocName === kycDoc.pan &&
      !motorRequest?.frontId &&
      motorRequest?.panFileName
    ) {
      dispatchMotorQuote("frontId", motorRequest?.panFileName);
      dispatchMotorQuote("kycImageLink", motorRequest?.panFileLink);
    } else if (selectedDocName === kycDoc.adhaar) {
      if (!motorRequest?.frontId && motorRequest?.aadhaarFrontFileName) {
        dispatchMotorQuote("frontId", motorRequest?.aadhaarFrontFileName);
      }
      if (!motorRequest?.backId && motorRequest?.aadhaarBackFileName) {
        dispatchMotorQuote("backId", motorRequest?.aadhaarBackFileName);
        dispatchMotorQuote("kycImageLink", motorRequest?.aadhaarFileLink);
      }
      if (
        !motorRequest?.form60Id &&
        kycMode === kycType.ovd &&
        motorRequest?.panFileName &&
        motorRequest?.panFileLink
      ) {
        dispatchMotorQuote("form60Id", motorRequest?.panFileName);
        dispatchMotorQuote("form60Link", motorRequest?.panFileLink);
      }
    } else if (
      selectedDocName === kycDoc.passport &&
      !motorRequest?.frontId &&
      motorRequest?.passportFileName
    ) {
      dispatchMotorQuote("frontId", motorRequest?.passportFileName);
      dispatchMotorQuote("kycImageLink", motorRequest?.passportFileLink);
    }
  };

  useEffect(() => {
    console.log("kycFields useEffect", kycFields);
  }, [kycFields]);

  const handleImagesUploadToS3 = async (data) => {
    // debugger;
    console.log("reqField", docImages, data);
    console.log("reqField", kycFields);
    const reqField = kycFields.find(
      (doc) => doc?.docName === data?.docName
    ).requiredField;
    console.log("reqField", reqField);

    console.log("", data?.docName === kycDoc.pan);
    console.log("", reqField.includes("uploadFrontPage"));
    console.log("", docImages?.frontId);
    if (
      data?.docName === kycDoc.pan &&
      reqField.includes("uploadFrontPage") &&
      docImages?.frontId
    ) {
      handleKycDocUpload(docImages?.frontId, "frontId");
    } else if (data?.docName === kycDoc.adhaar)
      if (
        reqField.includes("uploadFrontPage") &&
        reqField.includes("uploadBackPage") &&
        docImages?.frontId &&
        !docImages?.backId &&
        motorRequest?.docImages?.backId
      ) {
        const mergedPdf = await PDFDocument.create();

        if (docImages.frontId.type === "application/pdf") {
          // Handle PDF front
          const frontPdfBytes = await docImages.frontId.arrayBuffer();
          const frontPdf = await PDFDocument.load(frontPdfBytes);
          const frontPages = await mergedPdf.copyPages(
            frontPdf,
            frontPdf.getPageIndices()
          );
          frontPages.forEach((page) => mergedPdf.addPage(page));
        } else {
          // Handle image front
          const frontImageData = await getBase64(docImages.frontId);
          const tempPdf = new jsPDF();
          tempPdf.addImage(frontImageData, "JPEG", 10, 10, 180, 160);
          const tempPdfBytes = tempPdf.output("arraybuffer");
          const frontImagePdf = await PDFDocument.load(tempPdfBytes);
          const [frontPage] = await mergedPdf.copyPages(frontImagePdf, [0]);
          mergedPdf.addPage(frontPage);
        }

        if (motorRequest?.docImages.backId.type === "application/pdf") {
          // Handle PDF back
          const backPdfBytes =
            await motorRequest?.docImages.backId.arrayBuffer();
          const backPdf = await PDFDocument.load(backPdfBytes);
          const backPages = await mergedPdf.copyPages(
            backPdf,
            backPdf.getPageIndices()
          );
          backPages.forEach((page) => mergedPdf.addPage(page));
        } else {
          // Handle image back
          const backImageData = await getBase64(motorRequest?.docImages.backId);
          const tempPdf = new jsPDF();
          tempPdf.addImage(backImageData, "JPEG", 10, 10, 180, 160);
          const tempPdfBytes = tempPdf.output("arraybuffer");
          const backImagePdf = await PDFDocument.load(tempPdfBytes);
          const [backPage] = await mergedPdf.copyPages(backImagePdf, [0]);
          mergedPdf.addPage(backPage);
        }

        const mergedPdfBytes = await mergedPdf.save();
        const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        // previewPDF(pdfBlob);
        await handleKycDocUpload(pdfBlob, "adhaar");

        console.log("docImages", docImages);

        let docImagesNew = {
          ...docImages,
          backId: motorRequest?.docImages.backId,
        };
        console.log("docImagesNew", docImagesNew);
        dispatchMotorQuote("docImages", docImagesNew);

        // generatePDF("bothSide", false);
      } else if (
        reqField.includes("uploadFrontPage") &&
        reqField.includes("uploadBackPage") &&
        !docImages?.frontId &&
        docImages?.backId
      ) {
        const mergedPdf = await PDFDocument.create();

        if (motorRequest?.docImages.frontId.type === "application/pdf") {
          // Handle PDF front
          const frontPdfBytes =
            await motorRequest?.docImages.frontId.arrayBuffer();
          const frontPdf = await PDFDocument.load(frontPdfBytes);
          const frontPages = await mergedPdf.copyPages(
            frontPdf,
            frontPdf.getPageIndices()
          );
          frontPages.forEach((page) => mergedPdf.addPage(page));
        } else {
          // Handle image front
          const frontImageData = await getBase64(
            motorRequest?.docImages.frontId
          );
          const tempPdf = new jsPDF();
          tempPdf.addImage(frontImageData, "JPEG", 10, 10, 180, 160);
          const tempPdfBytes = tempPdf.output("arraybuffer");
          const frontImagePdf = await PDFDocument.load(tempPdfBytes);
          const [frontPage] = await mergedPdf.copyPages(frontImagePdf, [0]);
          mergedPdf.addPage(frontPage);
        }

        if (docImages.backId.type === "application/pdf") {
          // Handle PDF back
          const backPdfBytes = await docImages.backId.arrayBuffer();
          const backPdf = await PDFDocument.load(backPdfBytes);
          const backPages = await mergedPdf.copyPages(
            backPdf,
            backPdf.getPageIndices()
          );
          backPages.forEach((page) => mergedPdf.addPage(page));
        } else {
          // Handle image back
          const backImageData = await getBase64(docImages.backId);
          const tempPdf = new jsPDF();
          tempPdf.addImage(backImageData, "JPEG", 10, 10, 180, 160);
          const tempPdfBytes = tempPdf.output("arraybuffer");
          const backImagePdf = await PDFDocument.load(tempPdfBytes);
          const [backPage] = await mergedPdf.copyPages(backImagePdf, [0]);
          mergedPdf.addPage(backPage);
        }

        const mergedPdfBytes = await mergedPdf.save();
        const pdfBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
        // previewPDF(pdfBlob);
        await handleKycDocUpload(pdfBlob, "adhaar");

        console.log("docImages", docImages);

        let docImagesNew = {
          ...docImages,
          frontId: motorRequest?.docImages.frontId,
        };
        console.log("docImagesNew", docImagesNew);
        dispatchMotorQuote("docImages", docImagesNew);
      } else if (
        reqField.includes("uploadFrontPage") &&
        reqField.includes("uploadBackPage") &&
        docImages?.frontId &&
        docImages?.backId
      ) {
        generatePDF("bothSide", false);
      }
    if (reqField.includes("insured_photo") && docImages?.insuredPhotoId) {
      handleKycDocUpload(docImages?.insuredPhotoId, "insuredPhotoId");
    }
    if (reqField.includes("form60Link") && docImages?.form60Id) {
      handleKycDocUpload(docImages?.form60Id, "form60Id");
    }
    if (
      data?.docName === kycDoc.passport &&
      reqField.includes("uploadFrontPage") &&
      docImages?.frontId
    ) {
      handleKycDocUpload(docImages?.frontId, "frontId");
    }
  };

  // Add effect to sync email with motorRequest changes
  useEffect(() => {
    if (motorRequest?.email) {
      setEmail(motorRequest.email);
    }
  }, [motorRequest?.email]);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    dispatchMotorQuote("email", newEmail);
  };

  // Add effect to handle case changes
  useEffect(() => {
    return () => {
      // Cleanup when component unmounts or case changes
      setEmail("");
      setKycStatus(false);
      setProceedSpinner(false);
      // Reset other state variables as needed
    };
  }, [motorRequest?.caseId]);

  return (
    <form onSubmit={handleSubmit(submitKycDetails)} className="w-100">
      {kycConfig?.kycConfig?.isOvdAllowed && motorRequest?.ovdActive && (
        <div
          className="nav nav-pills mb-3 mt-3 tab_owner_tab"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${
                motorRequest?.kycType === "ckyc" || kycMode === "ckyc"
                  ? "active"
                  : ""
              }`}
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected={`${
                motorRequest?.kycType === "ckyc" ? true : false
              }`}
              onClick={() => {
                setKycMode("ckyc");
                dispatchMotorQuote("kycType", "ckyc");
              }}
            >
              CKYC
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              className={`nav-link ${
                motorRequest?.kycType === "ovd" || kycMode === "ovd"
                  ? "active"
                  : ""
              }`}
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected={`${
                motorRequest?.kycType === "ovd" ? true : false
              }`}
              onClick={() => {
                setKycMode("ovd");
                dispatchMotorQuote("kycType", "ovd");
              }}
              // onClick={handleOVDMode}
            >
              OVD
            </button>
          </li>
        </div>
      )}
      <div className="onwer-personal-details">
        <div className="row">
          {/* Full Name */}

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div
                className="did-floating-label-content"
                style={{ marginBottom: "0px" }}
              >
                <>
                  <select
                    className="did-floating-input"
                    {...register("docName", {
                      required: "Please Select Document Type to Verify",
                    })}
                    onChange={handleDocNameChange}
                    // defaultValue={kycStatusCheck ? motorRequest?.docName : ""}
                    disabled={kycStatus ? true : false}
                  >
                    <option value="">Select Document Type</option>
                    {kycConfig?.KycFieldConfig
                      // .filter((item) => item?.kycType === motorRequest?.kycType)
                      // .sort((a,b) => a.priority - b.priority)
                      .map(
                        (item) =>
                          item?.kycType === motorRequest?.kycType && (
                            <option
                              key={item?.docName}
                              value={item?.docName}
                              selected={
                                motorRequest?.docName === item?.docName
                                  ? true
                                  : false
                              }
                              // onClick={() => reset("")}
                            >
                              {item.docName === kycDoc.pan
                                ? kycDocName[item.docName]?.toUpperCase()
                                : kycDocName[item.docName]}
                            </option>
                          )
                      )}
                  </select>
                  <label htmlFor="idxx4" className="did-floating-label">
                    Select Document Type
                    <span className="text-danger">*</span>
                  </label>
                </>
              </div>
              <p className="f-error text-danger">{errors?.docName?.message}</p>
            </div>
          </div>

          {kycConfig &&
            kycFields &&
            kycFields.map((field) =>
              watch("docName") === field?.docName &&
              field?.requiredField?.length > 0
                ? [
                    ...field?.requiredField
                      .filter(
                        (input) =>
                          input === "docNumber" || input === "documentNo"
                      ) // First, pick the 'docNumber'
                      .concat(
                        // Then, render the rest of the fields
                        field?.requiredField.filter(
                          (input) =>
                            input !== "docNumber" && input !== "documentNo"
                        )
                      )
                      .map((input) => {
                        if (
                          [
                            "docLink",
                            "spouseName",
                            "fatherName",
                            "pincode",
                          ].includes(input)
                        ) {
                          return null;
                        }
                        return (
                          <div
                            className="col-md-6 mb-3 mt-3 mt-md-0"
                            key={input}
                          >
                            {input === "gender" ? (
                              <div className="did-floating-label-content">
                                <select
                                  name={input}
                                  className="did-floating-input"
                                  id={input}
                                  {...register(input, {
                                    required: `${input} is required`,
                                  })}
                                  disabled={
                                    motorRequest?.kycStatus === "completed"
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="" className="text-capitalize">
                                    {input}
                                  </option>
                                  <option
                                    value="M"
                                    selected={
                                      kycStatusCheck &&
                                      motorRequest?.gender === "M"
                                    }
                                  >
                                    Male
                                  </option>
                                  <option
                                    value="F"
                                    selected={
                                      kycStatusCheck &&
                                      motorRequest?.gender === "F"
                                    }
                                  >
                                    Female
                                  </option>
                                  <option
                                    value="O"
                                    selected={
                                      kycStatusCheck &&
                                      motorRequest?.gender === "O"
                                    }
                                  >
                                    Other
                                  </option>
                                </select>
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  {input}
                                  <span className="text-danger">*</span>
                                </label>
                                <p className="f-error text-danger">
                                  {errors?.[input]?.message}
                                </p>
                              </div>
                            ) : input === "dob" ? (
                              <div className="did-floating-label-content">
                                <DatePicker
                                  className="did-floating-input"
                                  selected={dob}
                                  onChange={handleDobChange}
                                  customInput={
                                    <ReactInputMask
                                      mask="99/99/9999"
                                      placeholder="DD/MM/YYYY"
                                      value={
                                        dob
                                          ? moment(dob).format("DD/MM/YYYY")
                                          : ""
                                      }
                                      onChange={handleDobInputChange}
                                      alwaysShowMask={false}
                                    />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText={
                                    motorRequest?.customerType ===
                                    CUSTOMER_TYPE.ORGANISATION
                                      ? "Enter Date of Incorporation"
                                      : "Enter Date of Birth"
                                  }
                                  maxDate={
                                    motorRequest?.customerType ===
                                    CUSTOMER_TYPE.ORGANISATION
                                      ? ""
                                      : moment().subtract(18, "years").toDate()
                                  }
                                  minDate={moment()
                                    .subtract(78, "years")
                                    .toDate()}
                                  showYearDropdown
                                  yearDropdownItemNumber={60}
                                  scrollableYearDropdown
                                  disabled={
                                    motorRequest?.kycStatus === "completed"
                                  }
                                  showTimeInput={false}
                                  showTimeSelect={false}
                                  timeInputLabel="Time:"
                                  defaultValue={
                                    motorRequest?.dob
                                      ? moment(motorRequest.dob).format(
                                          "DD/MM/YYYY"
                                        )
                                      : ""
                                  }
                                />
                                <label
                                  htmlFor="dob"
                                  className="did-floating-label text-capitalize"
                                >
                                  {motorRequest?.customerType ===
                                  CUSTOMER_TYPE.ORGANISATION
                                    ? "Date of Incorporation"
                                    : "Date of Birth"}
                                  <span className="text-danger">*</span>
                                </label>
                                {dobError ? (
                                  <p className="f-error text-danger">
                                    Date of Birth is required
                                  </p>
                                ) : null}
                              </div>
                            ) : input === "uploadFrontPage" ? (
                              <div className="did-floating-label-content">
                                {motorRequest?.frontId ? (
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="did-floating-input with-cross"
                                      value={motorRequest?.frontId}
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      className="remove-file-btn fs-4"
                                      onClick={() =>
                                        handleRemoveFile("frontId")
                                      }
                                    >
                                      &times;
                                    </button>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    name="frontId"
                                    className="did-floating-input"
                                    accept={
                                      "image/jpg, image/jpeg, application/pdf"
                                    }
                                    onChange={(e) => handlePictureUpload(e)}
                                    // {...register(input, {
                                    //   required: `${input} is required`,
                                    //   onChange: (e) => handleFileUpload(e),
                                    // })}
                                  />
                                )}
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  {input === "uploadFrontPage" &&
                                  watch("docName") === kycDoc.pan
                                    ? "Upload PAN"
                                    : watch("docName") === kycDoc.adhaar
                                    ? "Upload Aadhaar Front"
                                    : watch("docName") === kycDoc.passport
                                    ? "Upload Passport"
                                    : input}
                                  <span className="text-danger">*</span>
                                </label>

                                {!docImages?.frontId &&
                                  !motorRequest?.frontId && (
                                    <p className="f-error text-danger">
                                      Document is required{" "}
                                      {allowedFormat
                                        ? "(jpg/jpeg/pdf only)"
                                        : ""}
                                    </p>
                                  )}
                              </div>
                            ) : input === "uploadBackPage" ? (
                              <div className="did-floating-label-content">
                                {motorRequest?.backId ? (
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="did-floating-input with-cross"
                                      value={motorRequest?.backId}
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      className="remove-file-btn fs-4"
                                      onClick={() => handleRemoveFile("backId")}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    name="backId"
                                    className="did-floating-input"
                                    accept={
                                      "image/jpg, image/jpeg, application/pdf"
                                    }
                                    onChange={(e) => handlePictureUpload(e)}
                                    // {...register(input, {
                                    //   required: `${input} is required`,
                                    //   onChange: (e) => handleFileUpload(e),
                                    // })}
                                  />
                                )}
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  {input === "uploadBackPage" &&
                                  watch("docName") === kycDoc.adhaar
                                    ? "Upload Aadhaar Back"
                                    : input}
                                  <span className="text-danger">*</span>
                                </label>
                                {!docImages?.backId &&
                                  !motorRequest?.backId && (
                                    <p className="f-error text-danger">
                                      Document is required{" "}
                                      {allowedFormat
                                        ? "(jpg/jpeg/pdf only)"
                                        : ""}
                                    </p>
                                  )}
                              </div>
                            ) : input === "insured_photo" ? (
                              <div className="did-floating-label-content">
                                {motorRequest?.insuredPhotoId ? (
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="did-floating-input with-cross"
                                      value={motorRequest?.insuredPhotoId}
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      className="remove-file-btn fs-4"
                                      onClick={() =>
                                        handleRemoveFile("insuredPhotoId")
                                      }
                                    >
                                      &times;
                                    </button>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    name="insuredPhotoId"
                                    className="did-floating-input"
                                    accept={"image/jpg, image/jpeg"}
                                    onChange={(e) => handlePictureUpload(e)}
                                    // {...register(input, {
                                    //   required: `${input} is required`,
                                    //   onChange: (e) => handleFileUpload(e),
                                    // })}
                                  />
                                )}
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  Upload Insurer Photo
                                  <span className="text-danger">*</span>
                                </label>
                                {!docImages?.insuredPhotoId &&
                                  !motorRequest?.insuredPhotoId && (
                                    <p className="f-error text-danger">
                                      Document is required{" "}
                                      {allowedFormat ? "(jpg/jpeg only)" : ""}
                                    </p>
                                  )}
                              </div>
                            ) : input === "form60Link" ? (
                              <div className="did-floating-label-content">
                                {motorRequest?.form60Id ? (
                                  <div className="input-container">
                                    <input
                                      type="text"
                                      className="did-floating-input with-cross"
                                      value={motorRequest?.form60Id}
                                      readOnly
                                    />
                                    <button
                                      type="button"
                                      className="remove-file-btn fs-4"
                                      onClick={() =>
                                        handleRemoveFile("form60Id")
                                      }
                                    >
                                      &times;
                                    </button>
                                  </div>
                                ) : (
                                  <input
                                    type="file"
                                    name="form60Id"
                                    className="did-floating-input"
                                    accept={
                                      "image/jpg, image/jpeg, application/pdf"
                                    }
                                    onChange={(e) => handlePictureUpload(e)}
                                    // {...register(input, {
                                    //   required: `${input} is required`,
                                    //   onChange: (e) => handleFileUpload(e),
                                    // })}
                                  />
                                )}
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  Upload Pan/Form 60
                                  <span className="text-danger">*</span>
                                </label>
                                {!docImages?.form60Id &&
                                  !motorRequest?.form60Id && (
                                    <p className="f-error text-danger">
                                      Document is required{" "}
                                      {allowedFormat
                                        ? "(jpg/jpeg/pdf only)"
                                        : ""}
                                    </p>
                                  )}
                              </div>
                            ) : (
                              <div className="did-floating-label-content">
                                <input
                                  type={
                                    input === "dob"
                                      ? "date"
                                      : input === "mobileNumber"
                                      ? "number"
                                      : "text"
                                  }
                                  className="did-floating-input"
                                  {...register(input, {
                                    required: `${input} is required`,
                                    pattern:
                                      input === "docNumber" ||
                                      input === "documentNo"
                                        ? {
                                            value:
                                              watch("docName") === kycDoc.pan
                                                ? new RegExp(
                                                    /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/
                                                  )
                                                : "",
                                            // new RegExp(
                                            //     field.regexPattern
                                            //   ),
                                            message:
                                              "Invalid document number format",
                                          }
                                        : input === "mobileNumber"
                                        ? {
                                            value:
                                              /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                                            message: "Invalid Mobile Number",
                                          }
                                        : undefined,
                                  })}
                                  onInput={(e) =>
                                    input === "mobileNumber"
                                      ? (e.target.value = e.target.value.slice(
                                          0,
                                          10
                                        ))
                                      : ""
                                  }
                                  onChange={(e) => {
                                    if (input === "mobileNumber") {
                                      let value = e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      );
                                      if (
                                        value.length > 0 &&
                                        (value.startsWith("0") ||
                                          value.startsWith("1") ||
                                          value.startsWith("2") ||
                                          value.startsWith("3") ||
                                          value.startsWith("4") ||
                                          value.startsWith("5"))
                                      ) {
                                        value = value.substring(1);
                                      }
                                      e.target.value = value;
                                    }
                                    handleInputChange(e);
                                  }}
                                  inputMode={
                                    input === "mobileNumber" ? "numeric" : ""
                                  }
                                  defaultValue={
                                    input === "firstName"
                                      ? field?.requiredField?.includes(
                                          "lastName"
                                        )
                                        ? motorRequest.middleName
                                          ? `${motorRequest.firstName} ${motorRequest.middleName} `
                                          : `${motorRequest.firstName}`
                                        : motorRequest.middleName
                                        ? `${motorRequest.firstName} ${motorRequest.middleName} ${motorRequest.lastName}`
                                        : `${motorRequest.firstName} ${motorRequest.lastName}`
                                      : input === "lastName"
                                      ? `${motorRequest.lastName}`
                                      : input === "dob"
                                      ? motorRequest?.dob
                                      : input === "docNumber"
                                      ? motorRequest?.docNumber
                                      : input === "mobileNumber"
                                      ? motorRequest?.mobileNumber
                                      : null
                                  }
                                  maxLength={25}
                                  disabled={
                                    motorRequest?.kycStatus === "completed"
                                      ? true
                                      : false
                                  }
                                  name={input}
                                  // onChange={handleInputChange}
                                />
                                <label
                                  htmlFor={input}
                                  className="did-floating-label text-capitalize"
                                >
                                  {input === "docNumber" ? field.label : input}
                                  <span className="text-danger">*</span>
                                </label>
                                <p className="f-error text-danger text-capitalize">
                                  {errors?.[input]?.message}
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      }),
                  ]
                : null
            )}

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div className="did-floating-label-content">
                <input
                  type="email"
                  className="did-floating-input fullName"
                  {...register("email", {
                    required: "email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  value={email}
                  onChange={handleEmailChange}
                  onInput={(e) => {
                    let inputValue = e.target.value;
                    if (inputValue.includes(".com")) {
                      inputValue = inputValue.replace(/\.com.*/, ".com");
                    } else if (inputValue.includes(".in")) {
                      inputValue = inputValue.replace(/\.in.*/, ".in");
                    }
                    e.target.value = inputValue;
                  }}
                  id="mail2"
                  placeholder=" "
                />
                <label htmlFor="idxx4" className="did-floating-label">
                  Email<span className="text-danger">*</span>
                </label>
                <p className="f-error text-danger">{errors?.email?.message}</p>
              </div>
            </div>
          </div>

          {motorRequest?.customerType !== CUSTOMER_TYPE.ORGANISATION &&
            kycConfig &&
            kycFields &&
            kycFields.some(
              (field) =>
                field.requiredField.includes("fatherName") ||
                kycFields.some((field) =>
                  field.requiredField.includes("spouseName")
                )
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <select
                    name="maritalStatus"
                    className="did-floating-input"
                    id="maritalStatus"
                    // {...register("maritalStatus", {
                    //   required: "Marital Status is required",
                    // })}
                    onChange={handleMaritalStatus}
                    defaultValue={motorRequest?.maritalStatus}
                  >
                    <option value="">Marital Status</option>
                    <option value="married">Married</option>
                    <option value="unmarried">Unmarried</option>
                  </select>
                  <label htmlFor="maritalStatus" className="did-floating-label">
                    Marital Status
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.maritalStatus?.message}
                  </p>
                </div>
              </div>
            )}

          {maritalStatus === "married" &&
            kycFields &&
            kycFields.some((field) =>
              field.requiredField.includes("spouseName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("spouseName", {
                      required: "Spouse's name is required",
                    })}
                    defaultValue={motorRequest?.spouseName}
                  />
                  <label htmlFor="spouseName" className="did-floating-label">
                    Spouse's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.spouseName?.message}
                  </p>
                </div>
              </div>
            )}

          {/* Conditional rendering for fatherName based on marital status */}
          {(selectedPlan?.insurerId === insurers.shriram.insurerId ||
            maritalStatus === "unmarried") &&
            kycFields &&
            kycFields.some((field) =>
              field.requiredField.includes("fatherName")
            ) && (
              <div className="col-md-6 mb-2">
                <div className="did-floating-label-content">
                  <input
                    type="text"
                    className="did-floating-input"
                    {...register("fatherName", {
                      required: "Father's name is required",
                    })}
                    defaultValue={motorRequest?.fatherName}
                  />
                  <label htmlFor="fatherName" className="did-floating-label">
                    Father's Name
                    <span className="text-danger">*</span>
                  </label>
                  <p className="f-error text-danger">
                    {errors?.fatherName?.message}
                  </p>
                </div>
              </div>
            )}

          {kycFields &&
            kycFields
              .find((field) => field.docName === watch("docName"))
              ?.requiredField.includes("pincode") && (
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <ReactSelect
                      options={pincodeData}
                      onChange={(val) => {
                        handleSelectPincode(val);
                      }}
                      placeholder="Select area pincode"
                      className="form-select did-floating-input"
                      defaultValue={motorRequest?.pincode}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Select Pincode
                      <span className="text-danger">*</span>
                    </label>
                    {pincodeError && (
                      <p className="f-error text-danger">Pincode is required</p>
                    )}
                  </div>
                </div>
              </div>
            )}

          {kycConfig?.kycConfig?.kycStep !== "in_proposal" &&
            motorRequest?.kycStatus !== "completed" && (
              <div className="col-md-12">
                <div className="next-btn-btn">
                  <button
                    type="button"
                    className="proceedBtn1"
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type={
                      selectedPlan?.Api_name === insurers.Digit.name
                        ? "button"
                        : redirection
                        ? "button"
                        : "submit"
                    }
                    className="proceedBtn1"
                    // onClick={() => handleNextStep()}
                    onClick={() => {
                      redirection ? getKycStatus() : null;
                    }}
                  >
                    {proceedSpinner ? (
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    ) : redirection ? (
                      "Verify Kyc"
                    ) : (
                      "Complete KYC"
                    )}
                  </button>
                </div>
              </div>
            )}

          {(kycConfig?.kycConfig?.kycStep === "in_proposal" ||
            motorRequest?.kycStatus === "completed") && (
            <>
              {!(
                (kycConfig &&
                  kycFields &&
                  kycFields.some((field) =>
                    field.requiredField.includes("fatherName")
                  )) ||
                (kycConfig &&
                  kycFields &&
                  kycFields.some((field) =>
                    field.requiredField.includes("spouseName")
                  ))
              ) &&
                motorRequest?.customerType !== CUSTOMER_TYPE.ORGANISATION && (
                  <div className="col-md-6 mb-2">
                    <div className="onwer-details">
                      <div className="did-floating-label-content">
                        <select
                          name="maritalStatus"
                          className="did-floating-input"
                          id="maritalStatus"
                          // {...register("maritalStatus", {
                          //   required: "Marital Status is required",
                          // })}
                          onChange={handleMaritalStatus}
                          defaultValue={motorRequest?.maritalStatus}
                        >
                          <option value="">Marital Status</option>
                          <option value="married">Married</option>
                          <option value="unmarried">Unmarried</option>
                        </select>
                        <label htmlFor="idxx4" className="did-floating-label">
                          Marital Status
                          <span className="text-danger">*</span>
                        </label>
                        <p className="f-error text-danger">
                          {addressErrors?.maritalStatus}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {motorRequest?.customerType === CUSTOMER_TYPE.ORGANISATION &&
                selectedPlan?.isGstNumberRequiredInProposal && (
                  <div className="col-md-6 mb-2">
                    <div className="onwer-details">
                      <div className="did-floating-label-content">
                        <input
                          type="text"
                          className=" did-floating-input text-uppercase"
                          placeholder=""
                          // {...register("addressLine1", {
                          //   required: "Address 1 is required",
                          // })}
                          value={gstin}
                          name="gstin"
                          onChange={(e) => handleGstin(e)}
                          onInput={(e) => {
                            e.target.value = e.target.value.slice(0, 15);
                            e.target.value = e.target.value.replace(
                              /[^a-zA-Z0-9]/g,
                              ""
                            );
                          }}
                          defaultValue={motorRequest?.gstIn}
                          maxLength={30}

                          //   onChange={(e) => handleSelectAddress1(e.target.value)}
                        />
                        <label htmlFor="idxx4" className="did-floating-label">
                          GSTIN<span className="text-danger">*</span>
                        </label>
                        {gstInError && (
                          <p className="f-error text-danger">
                            GSTIN is required
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine1", {
                      //   required: "Address 1 is required",
                      // })}
                      // value={address.addressLine1}
                      name="addressLine1"
                      onChange={IsEdited}
                      value={address?.addressLine1}
                      // defaultValue={address1}
                      maxLength={100}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address Line 1<span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine1}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine2", {
                      //   required: "Address 2 is required",
                      // })}
                      // onChange={(e) =>
                      //   dispatchMotorQuote("addressLine2", e.target.value)
                      // }
                      name="addressLine2"
                      onChange={IsEdited}
                      value={address?.addressLine2}
                      // defaultValue={address2}
                      maxLength={200}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address Line 2<span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine2}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine3", {
                      //   required: "Address 3 is required",
                      // })}
                      // onChange={(e) =>
                      //   dispatchMotorQuote("addressLine3", e.target.value)
                      // }
                      name="addressLine3"
                      onChange={IsEdited}
                      value={address?.addressLine3}
                      // defaultValue={address3}
                      maxLength={200}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address Line 3 / Landmark
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine3}
                    </p>
                  </div>
                </div>
              </div>

              {kycFields &&
                !kycFields
                  .find((field) => field.docName === watch("docName"))
                  ?.requiredField.includes("pincode") && (
                  <>
                    <div className="col-md-6 mb-2">
                      <div className="onwer-details">
                        <div className="did-floating-label-content">
                          <ReactSelect
                            options={pincodeData}
                            onChange={(val) => {
                              handleSelectPincode(val);
                            }}
                            placeholder="Select area pincode"
                            className="form-select did-floating-input"
                            defaultValue={motorRequest?.pincode}
                          />
                          <label htmlFor="idxx4" className="did-floating-label">
                            Select Pincode
                            <span className="text-danger">*</span>
                          </label>
                          {pincodeError && (
                            <p className="f-error text-danger">
                              Pincode is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="onwer-details">
                        <div className="did-floating-label-content">
                          <input
                            type="text"
                            placeholder=""
                            className="did-floating-input"
                            {...register("city", {
                              // required: "City is required",
                            })}
                            disabled
                            defaultValue={motorRequest?.city}
                          />
                          <label
                            htmlFor="idxx4"
                            className="did-floating-label uppercase"
                          >
                            Enter City
                            <span className="text-danger">*</span>
                          </label>
                          <p className="f-error text-danger">
                            {addressErrors.city}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="onwer-details">
                        <div className="did-floating-label-content">
                          <input
                            list="text"
                            placeholder=""
                            name="State"
                            {...register("state", {
                              // required: "State is required",
                            })}
                            defaultValue={motorRequest?.state}
                            disabled
                            //   onChange={(e) => handleSelectState(e.target.value)}
                            className="did-floating-input uppercase"
                          />
                          <label htmlFor="idxx4" className="did-floating-label">
                            Enter State
                            <span className="text-danger">*</span>
                          </label>
                          <p className="f-error text-danger">
                            {addressErrors.state}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {/* <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="file"
                      placeholder=""
                      accept=""
                      name="frontId"
                      multiple
                      onChange={(e) => handlePictureUpload(e)}
                      //   onChange={(e) => handleSelectState(e.target.value)}
                      className="did-floating-input uppercase"
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Upload Picture
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="file"
                      placeholder=""
                      accept=""
                      name="backId"
                      onChange={(e) => handlePictureUpload(e)}
                      //   onChange={(e) => handleSelectState(e.target.value)}
                      className="did-floating-input uppercase"
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Upload Picture
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="col-md-12">
                <div className="next-btn-btn">
                  <button
                    type="button"
                    className="proceedBtn1"
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type={
                      selectedPlan?.insurerId === insurers.Digit.insurerId
                        ? "submit"
                        : selectedPlan?.insurerId === insurers.shriram.insurerId
                        ? "submit"
                        : "button"
                    }
                    // onClick={() => {
                    //   setActiveStep2(true);
                    //   // motorRequest?.personalAccident
                    //   //   ? (setCurrentStep(2), setActiveStep2(true))
                    //   //   : setCurrentStep(3),
                    //   //   setActiveStep3(true);
                    //   setCurrentStep(2);
                    // }}
                    onClick={
                      selectedPlan?.insurerId === insurers.Digit.insurerId
                        ? null
                        : selectedPlan?.insurerId === insurers.shriram.insurerId
                        ? null
                        : selectedPlan?.insurerId === insurers.TATA.insurerId
                        ? null
                        : handleNextClick
                    }
                    className="proceedBtn1"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <></>
      </div>
      {redirectionModal && (
        <RedirectionModal
          show={redirectionModal}
          setModal={setRedirectionModal}
          url={redirectionUrl}
        />
      )}
      {/* {verifyKycModal && (
        <VerifyKycModal
          show={verifyKycModal}
          setModal={setVerifyKycModal}
          url={redirectionUrl}
          getKycStatus={getKycStatus}
          setProceedSpinner={setProceedSpinner}
          proceedSpinner={proceedSpinner}
        />
      )} */}
      {displayOtpField && (
        <OtpModel
          show={displayOtpField}
          setModel={setDisplayOtpField}
          otpLength={otpLength}
          setRedirectionUrl={setRedirectionUrl}
          kycStatus={kycStatus}
          setKycStatus={setKycStatus}
          setProceedSpinner={setProceedSpinner}
          proceedSpinner={proceedSpinner}
          setOtpLength={setOtpLength}
        />
      )}
    </form>
  );
};

export default KYC;
